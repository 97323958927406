@font-face {
  font-family: 'mont';
  src: url('./assets/fonts/mont/Mont-HeavyDEMO.otf');
  font-display: swap;
}

body, code {
  margin: 0;
  font-family: 'mont' !important;
  font-weight: bold !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}